import React, { useEffect, useContext, useRef, memo } from 'react'
import { TemplateContext } from '../context/templateContext'
import { useNavigate } from "react-router-dom";
import { gsap } from 'gsap'
import { ReactComponent as Scene } from "../images/scene.svg"

const Header = (props) => {
  const navigate = useNavigate();
  const { setHeightHeader, toggleCircleMenu, switchStatusCircleMenu, setParamsToActiveStep, initToActiveStep } = useContext(TemplateContext)
  let headerHook = useRef(null)

  useEffect(() => {
    setHeightHeader(headerHook.clientHeight)
    // eslint-disable-next-line
  }, [props])

  useEffect(() => {
    if (props.logo) {
      gsap.to('#logo', {
        fill: 'white'
      })
    }
  }, [props])

  const changeActiveItem = (str) => {
    initToActiveStep()
    toggleCircleMenu(str)
    setParamsToActiveStep('step', 1)
    if (!props.statusMenu) switchStatusCircleMenu()
  }
  const getLogo = (
    <div
      className={'header' + props.layout}
      ref={el => { headerHook = el }}
    >
      <div className={`header${props.layout}__logo`}>
        <svg className='svgIcon' viewBox='0 0 800 100'>
          <path
            id='logo'
            d={props.logo}
            fill='rgb(0,0,0)'
          />
        </svg>
      </div>
      <div className={`header${props.layout}__menu`}>
        <div className="menu_item" onClick={() => {
          navigate("/");
          toggleCircleMenu('SDLC')
          switchStatusCircleMenu()
          initToActiveStep()
          setParamsToActiveStep('step', 0)
        }}>
          <Scene />
        </div>

        <div className="menu_item" onClick={() => {
          changeActiveItem('ABOUT')
          navigate("/about");
        }}>
          About
        </div>

        {/* <div className="menu_item" onClick={() => { 
          changeActiveItem('PORTFOLIO')
          navigate("/portfolio");
        }}>
          Portfolio
        </div>*/}
        <div className="menu_item">
          <a href='https://www.linkedin.com/in/elenamena-denver'>Linkedin</a>
        </div>
        <div className="menu_item">
          <a href='https://github.com/dithiane'>Github</a>
        </div>
      </div>
    </div>
  )

  return (
    props.logo
      ? getLogo
      : null
  )
}

export default memo(Header)
