import React, { useEffect, useState, memo } from 'react'
import helper from '../helpers/helper'

function Quote (props) {
  const [X, setX] = useState()
  const [Y, setY] = useState()
  const [scaleX, setScaleX] = useState()

  useEffect(() => {
    const setParameters = () => {
      if (props.vertical) {
        setX(props.width / 18)
        setY(props.width / 300)
        setScaleX(helper.roundTo(props.width / 1200))
      } else {
        setX(props.width / 25)
        setY(props.width / 25)
        setScaleX(helper.roundTo(props.width / 1200))
      }
    }
    setParameters()
  }, [props.vertical, props.width])

  return (
    scaleX
      ? <g id='quotePerelman' transform={helper.moveToXY_ScaleXY(X, Y, scaleX, scaleX)}>
          <path id='quote' d={props.vertical ? props.quotevertical : props.quote} fill='rgb(255,255,255)' />
        </g>
      : null
  )
}

export default memo(Quote)
