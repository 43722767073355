import React, { memo } from 'react'
import Home from '../components/home'
import About from '../components/about'
import Portfolio from '../components/portfolio'
import { Routes, Route } from 'react-router-dom'

const SvgScene = (props) => {


  return (
    <div className='svgArea'>
      <Routes>
        <Route
          path='/about'
          element={
            <About
              layout={props.layout}
              width={props.width}
              height={props.height} />}
        />
        <Route
          path='/portfolio'
          element={
            <Portfolio
              layout={props.layout}
              width={props.width}
              height={props.height} />}
        />
        <Route
          path='/'
          element={
            <Home
              quote={props.quote}
              quotevertical={props.quotevertical}
              menu={props.menu}
              animation={props.animation}
              layout={props.layout}
              width={props.width}
              height={props.height}
            />}
        />
      </Routes>
    </div>

  )
}
export default memo(SvgScene)
