import React, { useContext, memo } from 'react'
import { TemplateContext } from '../context/templateContext'
import ActiveScene from './activeScene'
import CircleMenu from './circlemenu'
import helper from '../helpers/helper'
import Spinner from './spinner'
import Quote from './quote'
import {
  asId,
  bdModuleHight,
  bdId,
} from '../helpers/constants'

const wImage = 1026
let imageObj = null
let titleProps = null

const Home = (props) => {
  const { getCompressedImageProperties, isStepsExists, activeElement } = useContext(TemplateContext)
  const getHeightForLayout = () => {
    return helper.getConstantBy(bdModuleHight, props.layout)
  }
  const getQuote = () => {
    switch (props.layout) {
      case 'Landscape':
        return <Quote vertical={false} width={props.width} height={props.height} quote={props.quote} />
      case 'Portrait':
        if ((props.width / props.height) < 0.74) { return <Quote vertical width={props.width} height={props.height} quotevertical={props.quotevertical} /> } else { return null }
      default:
        break
    }
  }
  const getSelectedItem = () => {
    if (activeElement) {
      if ((isStepsExists() && activeElement.name !== "ABOUT" && activeElement.name !== "PORTFOLIO")) {
        titleProps = helper.getPropertyForLayout(props.layout, activeElement.description)
        imageObj = getCompressedImageProperties(wImage, getHeightForLayout())
        if (imageObj && titleProps) {
          return (
            <g id={bdId}>
              <ActiveScene
                key={asId}
                x={imageObj.x}
                y={imageObj.y}
                scale={imageObj.scale}
                xTitle={titleProps.x}
                yTitle={titleProps.y}
                scaleTitle={titleProps.scale}
                width={props.width}
                height={props.height}
                layout={props.layout}
              />
            </g>
          )
        } else return null
      }

    } else return null
  }

  return (
    <svg
      className='svgBody'
      viewBox={helper.concatinatedViewBox(props.width, props.height)}
      width='100%'
      height='100%'
      preserveAspectRatio='none'
    > {props.menu ? null : getQuote()}
      {props.menu ? activeElement ? getSelectedItem() : <Spinner />
        : null}
      <CircleMenu
        menu={props.menu}
        animation={props.animation}
        width={props.width}
        height={props.height}
      />
    </svg>
  )
}
export default memo(Home)
