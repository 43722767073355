import React, { useEffect, useContext, useRef, memo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { TemplateContext } from '../context/templateContext'

const Footer = (props) => {
  const navigate = useNavigate();
  const { setHeightFooter, toggleCircleMenu, switchStatusCircleMenu, setParamsToActiveStep, initToActiveStep, layout } = useContext(TemplateContext)
  const [chatra, setChatra] = useState(null)
  let footerHook = useRef(null)

  useEffect(() => {
    setHeightFooter(footerHook.clientHeight)

    // eslint-disable-next-line
  }, [props])

  const getChatraScript = () => {
    return (

      <script>
        key = chatra

        {(function (d, w, c) {
          w.ChatraID = 'Auuo7RbCKCeDXRGNu'
          const s = d.createElement('script')
          w[c] =
            w[c] ||
            function () {
              (w[c].q = w[c].q || []).push(arguments)
            }
          s.async = true
          s.src = 'https://call.chatra.io/chatra.js'
          if (d.head) d.head.appendChild(s)
        })(document, window, 'Chatra')}
        ;

      </script>

    )
  }

  const returnToHome = () => {
    navigate("/");
    toggleCircleMenu('SDLC')
    switchStatusCircleMenu()
    initToActiveStep()
    setParamsToActiveStep('step', 0)
  }

  useEffect(() => {
    if (props.menu) setChatra(getChatraScript())
    else deleteChatra()
  }, [props.menu])

  const deleteChatra = () => {
    const element = document.getElementById('chatra')
    if (element) { element.parentNode.removeChild(element) }
  }
  const getFooter = () => {
    switch (props.menu) {
      case true:
        if (layout === 'Portrait') {
          return (
            <div className={`footer${props.layout}`}>
              <Link
                className={`footer${props.layout}__textcenter`}
                to='/'
                ref={el => { footerHook = el }}
                onClick={() => returnToHome()}>© 2024 dithiane

              </Link>
              {chatra}
            </div >
          )
        } else {
          return (
            <div className={'footer' + layout}
              ref={el => { footerHook = el }}>
              <Link className={`footer${props.layout}__textleft`}
                to='/'
                onClick={() => returnToHome()}
              >© 2024 Dithiane</Link>
              <div className={`footer${props.layout}__textright`}>Chat with me... </div>
              {chatra}
            </div>
          )
        }
      case false:
        return (
          <div className={`footer${props.layout}`} onClick={() => returnToHome()}>
            <Link
              className={`footer${props.layout}__textcenter`}
              to='/'
              ref={el => { footerHook = el }}
            >© 2024 dithiane
            </Link>
          </div>
        )

      default:
        return null
    }
  }

  return (
    getFooter()
  )
}

export default memo(Footer)
