import React, { memo } from 'react'
import helper from '../../helpers/helper'
import {
  tlId,
  tlXText,
  tlYText,
  tlFontSize

} from '../../helpers/constants'

const Title = (props) => {
  const xTitle = helper.getXforTextFromStartPoint(tlXText, props.titleText)
  const titleShape = (
    <>
      <defs>
        <linearGradient id='lgrad' x1='100%' y1='50%' x2='0%' y2='50%'>
          <stop offset='0%' stopColor={props.title.background} stopOpacity='0' />
          <stop offset='40%' stopColor={props.title.background} stopOpacity='1' />
          <stop offset='60%' stopColor={props.title.background} stopOpacity='1' />
          <stop offset='100%' stopColor={props.title.background} stopOpacity='0' />
        </linearGradient>
      </defs>
      <rect
        id={tlId}
        transform={`translate(${-400} 10)`}
        x='0' y='0' rx='10' ry='10' width={1300} height='60'
        fill='url(#lgrad)'
      />
    </>
  )
  return (

    <g
      id={props.titleText}
      transform={helper.moveToXY_ScaleXY(props.xTitle, props.yTitle, props.scaleTitle)}
    >
      {titleShape}
      <text
        transform={helper.moveToXY(xTitle, tlYText)}
        fontSize={tlFontSize}
        fontWeight={400}
        fill={props.color}
      >
        {props.titleText}
      </text>
    </g>
  )
}
export default memo(Title)
