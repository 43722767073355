import React from "react";
import { ReactComponent as Node } from "../images/nodedotjs.svg";
import { ReactComponent as Javascript } from "../images/javascript.svg";
import { ReactComponent as Html } from "../images/html5.svg";
import { ReactComponent as Css } from "../images/css3.svg";
import { ReactComponent as TypescriptC } from "../images/typescript.svg";
import { ReactComponent as ReactC } from "../images/react.svg";
import { ReactComponent as Redux } from "../images/redux.svg";
import { ReactComponent as Mui } from "../images/mui.svg";
import { ReactComponent as Angular } from "../images/angular.svg";
import { ReactComponent as Vuedotjs } from "../images/vuedotjs.svg";
import { ReactComponent as Mongodb } from "../images/mongodb.svg";
import { ReactComponent as Postgresql } from "../images/postgresql.svg";
import Me from "../images/me.jpg";

const About = (props) => {
  return (
    <>
      <div className="content">
        <section className="my_image">
          <img src={Me} alt="Me" />
        </section>
        <section className="info">
          <h1>
            GSAP animation superheroes developers fan, a developer using leading
            technologies and programming languages to delivery robust web
            applications.
          </h1>
        </section>
        <section className="info">
          <div className="section-name">Skills</div>
          <div className="icons-container">
            <Node className="icon" />
            <Javascript className="icon" />
            <TypescriptC className="icon" />
            <Html className="icon" />
            <Css className="icon" />
            <ReactC className="icon" />
            <Redux className="icon" />
            <Mui className="icon" />
            <Angular className="icon" />
            <Vuedotjs className="icon" />
            <Mongodb className="icon" />
            <Postgresql className="icon" />
          </div>
        </section>
        <section className="info">
          <div className="section-name">Career goals</div>
          My dream to be a full-stack web developer — an expert in both frontend
          and backend web development. Create the overall presentation of a
          website and engineer its functionality. Write code, interacting with
          databases, and managing servers.
        </section>
      </div>
    </>
  );
};

export default About;
