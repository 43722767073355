import React, { useContext } from 'react'
import { useLocation } from "react-router-dom"
import helper from '../helpers/helper'
import Header from './header'
import Footer from './footer'
import SvgScene from './svgScene'
import Spinner from './spinner'
import { TemplateContext } from '../context/templateContext'

const TemplateLayout = () => {
  const { loading, logo, windowWidth, windowHeight, layout, svgHight, statusMenu, animation, quote, quotevertical, dbStore } = useContext(TemplateContext)
  const location = useLocation().pathname
  const getBody = (
    <>
      <Header
        width={windowWidth}
        height={windowHeight}
        layout={layout}
        logo={logo}
        statusMenu={statusMenu}
      />
      <Footer
        width={windowWidth}
        height={windowHeight}
        layout={layout}
        menu={statusMenu}
      />
      <SvgScene
        width={windowWidth}
        height={svgHight}
        layout={layout}
        menu={statusMenu}
        animation={animation}
        quote={quote}
        quotevertical={quotevertical}
      />
    </>
  )

  return (

    <>
      {(helper.isUndefined(loading)) ||
        (helper.isUndefined(windowWidth)) ||
        (helper.isUndefined(layout)) ||
        (helper.isUndefined(svgHight)) ||
        (helper.isUndefined(logo)) ||
        (helper.isUndefined(quote)) ||
        (helper.isUndefined(quotevertical)) ||
        (helper.isUndefined(dbStore))
        ? location === "/" ? <Spinner /> : getBody : getBody}
    </>

  )
}

export default TemplateLayout
