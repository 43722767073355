import React, { useEffect, useRef, memo, useContext } from 'react'
import helper from '../../helpers/helper'
import {
  adModuleCar,
  adModuleSkiils,
  adModuleBackground,
  adLineKeyBase,
  adHLine,
  adXline,
  adId,
  adIdScreen,
  adX,
  adIdBackground,
  adBgrRadiusX,
  adBgrRadiusY,
  adBgrWidth,
  adBgrColor,
  adYTextLine,
  adIdButton,
  adButtonTransform,
  adButtonText,
  adButtonTetxtTransform,
  adFontSize,
  adFontWeight,
  adFontSizeButton,
  adColorTextButton,
  adPathButton1,
  adPath1Color,
  adPathButton2,
  adPath2Color,
  adPathButton3,
  adPath3Color,
  adPathButton4,
  adPath4Color,
  adOpacityButtonMore,
  adEndPosition
} from '../../helpers/constants'
import { gsap } from 'gsap'
import { TemplateContext } from '../../context/templateContext'

const Additions = (props) => {
  const { setParamsToActiveStep } = useContext(TemplateContext)

  let buttonPopUpHook = useRef(null)
  let screenPopUpHook = useRef(null)

  useEffect(() => {
    const startX = helper.getConstantBy(adModuleCar, props.layout)
    if (props.active.additionFlag) {
      popUpScreen(0, 'none')
      moveCar(startX, 0, 1, 'none')
    } else {
      setVisibility(buttonPopUpHook, 'inline', adOpacityButtonMore, 1)
    }

    return () => {
      if (props.last) { moveCar(adEndPosition, 0, 0) }
    }
    // eslint-disable-next-line
  }, [])

  const moveCar = (x, duration, opacity, ease = 'elastic') => {
    if (props.carHook.current) {
      gsap.to(
        props.carHook.current,
        { x: x, duration: duration, opacity: opacity, ease: ease }
      )
    }
  }

  const popUpScreen = (duration, ease = 'bounce') => {
    if (screenPopUpHook) {
      gsap.to(
        screenPopUpHook,
        { x: 0, duration: duration, opacity: 1, ease: ease }
      )
    }
    setParamsToActiveStep('additionFlag', true)
  }

  const setVisibility = (obj, display, opacity, duration = 0.1) => {
    if (obj) {
      gsap.to(
        [obj],
        { display: display, opacity: opacity, ease: 'back', duration: duration }
      )
    }
  }

  const mouseEnter = (e) => {
    const startX = helper.getConstantBy(adModuleCar, props.layout)
    if (buttonPopUpHook.attributes.style.value.includes('9')) {
      moveCar(startX, 1, 1)
      popUpScreen(1)
      setVisibility(buttonPopUpHook, 'none', 0, 1)
    }
  }

  const lineOfTheScreen = (i, startY, rows) => {
    return (
      <text
        key={adLineKeyBase + i}
        fontSize={adFontSize}
        fill='#fff'
        transform={`translate(${adXline} ${startY})`}
      >
        {rows}
      </text>
    )
  }
  const getLinkTag = (text, link) => {
    return (
      <a key={'key=' + text} href={link} style={{ fill: props.color }}>{text}</a>
    )
  }
  const getTheLink = (words, i, startY) => {
    let hrefLine
    const rows = []
    if (words.includes('<')) {
      const arrOfLineElements = words.split('<')
      arrOfLineElements.forEach(item => {
        if (item.includes('=')) {
          hrefLine = item.split('=')
          rows.push(getLinkTag(hrefLine[0], hrefLine[1]))
        } else rows.push(item)
      })
    } else rows.push(words)
    return lineOfTheScreen(i, startY, rows)
  }
  const getAddition = () => {
    const rows = []
    let startY = helper.getConstantBy(adModuleSkiils, props.layout)

    for (const i in props.addition) {
      rows.push(getTheLink(props.addition[i], i, startY))
      startY += 23
    }

    return rows
  }
  const getPopUpObject = () => {
    return (

      <g
        id={adId}
        key={adId}
        transform={props.transformPopUp}
        opacity={props.opacityPopUp}
      >

        <g
          id={adIdScreen}
          key={adIdScreen}
          ref={el => { screenPopUpHook = el }}
          opacity={0}
          transform={helper.moveToXY_ScaleXY(adX, 0, 1, 1)}
        >
          <rect
            id={adIdBackground}
            transform={helper.getConstantBy(adModuleBackground, props.layout)}
            x='0'
            y='0'
            rx={adBgrRadiusX}
            ry={adBgrRadiusY}
            width={adBgrWidth}
            height={adYTextLine + (props.addition.length) * adHLine}
            fill={adBgrColor}
          />
          {getAddition()}

        </g>
        <g
          id={adIdButton}
          key={adIdButton}
          onMouseEnter={(e) => mouseEnter(e)}
          ref={el => { buttonPopUpHook = el }}
          style={{ opacity: 0 }}
        >
          <g
            transform={adButtonTransform}
          >
            <path
              onMouseEnter={(e) => mouseEnter(e)}
              onMouseMove={(e) => mouseEnter(e)}
              onMouseOver={(e) => mouseEnter(e)}
              d={adPathButton1} fill={adPath1Color}
            />
            <path
              onMouseEnter={(e) => mouseEnter(e)}
              onMouseMove={(e) => mouseEnter(e)}
              onMouseOver={(e) => mouseEnter(e)}
              d={adPathButton2} fill={adPath2Color}
            />
            <path
              onMouseEnter={(e) => mouseEnter(e)}
              onMouseMove={(e) => mouseEnter(e)}
              onMouseOver={(e) => mouseEnter(e)}
              d={adPathButton3} fill={adPath3Color}
            />
            <path
              onMouseEnter={(e) => mouseEnter(e)}
              onMouseMove={(e) => mouseEnter(e)}
              onMouseOver={(e) => mouseEnter(e)}
              d={adPathButton4} fill={adPath4Color}
            />
          </g>
          <text
            key={adButtonText}
            id={adButtonText}
            transform={adButtonTetxtTransform}
            fontWeight={adFontWeight}
            fontSize={adFontSizeButton}
            fill={adColorTextButton}
          >
            More...
          </text>

        </g>
      </g>

    )
  }

  return (

    getPopUpObject()

  )
}
export default memo(Additions)
