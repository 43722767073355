
import React from 'react'
import TemplateLayout from './templateLayout'
import Sky from './sky'
import TemplateContextProvider from '../context/templateContext'

const Template = () => {
  return (

    <TemplateContextProvider>
      <TemplateLayout />
      <Sky />
    </TemplateContextProvider>

  )
}

export default Template
