import React, { memo } from 'react'
import helper from '../../helpers/helper'

const Shape = (props) => {
  const getStopOffsets = () => {
    const rows = []
    for (const i in props.internals.gradient.stopOffSet) {
      rows.push(
        <stop
          key={i}
          offset={props.internals.gradient.stopOffSet[i]}
          stopColor={props.internals.gradient.stopColor[i]}
          stopOpacity={props.internals.gradient.stopOpacity[i]}
        />
      )
    }
    return rows
  }

  const getShapeGradient = () => {
    if (!helper.isUndefined(props.internals.gradient)) {
      if (props.internals.gradient.idGradient) {
        return (
          <linearGradient
            id={props.internals.gradient.idGradient}
            x1={props.internals.gradient.x1}
            y1={props.internals.gradient.y1}
            x2={props.internals.gradient.x2}
            y2={props.internals.gradient.y2}
            gradientTransform={props.internals.gradient.gradientTransform}
            gradientUnits={props.internals.gradient.gradientUnits}
          >
            {getStopOffsets()}
          </linearGradient>

        )
      }
    } else return (null)
  }

  const fullPath = (gradient, path) => {
    return (
      <>
        {gradient}
        {path}
      </>
    )
  }

  const extendProperties = (path, properties) => {
    path = React.cloneElement(path, properties[0], properties[1])
    return path
  }

  const checkAttribute = (...attributes) => {
    const arr = []
    attributes.forEach(element => {
      if (element) arr.push(element)
    })
    return arr
  }

  const getShapeObject = () => {
    let pathWithProperties = null
    const gradient = getShapeGradient()

    const pathObj = (
      <path
        id={props.internals.name}
        d={props.internals.properties.path}
        fill={props.internals.gradient.idGradient ? `url(#${props.internals.gradient.idGradient})` : props.internals.properties.fill}
      />
    )

    if (props.internals.matrix || props.internals.properties.fillrule) {
      pathWithProperties = extendProperties(pathObj,
        checkAttribute(props.internals.matrix ? { transform: props.internals.matrix } : null,
          props.internals.properties.fillrule ? { fillRule: props.internals.properties.fillrule } : null))
    } else pathWithProperties = pathObj

    pathWithProperties = gradient ? fullPath(gradient, pathWithProperties) : pathWithProperties

    return pathWithProperties
  }

  return (
    getShapeObject()
  )
}
export default memo(Shape)
