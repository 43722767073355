import React, { useRef, useEffect, useContext, memo } from 'react'
import { gsap } from 'gsap'
import helper from '../helpers/helper'
import { useNavigate } from "react-router-dom";
import {
  cmColorSDLC,
  cmColorBackEnd,
  cmColorDataBase,
  cmColorLanguages,
  cmColorDeployment,
  cmColorTesting,
  cmColorFrontEnd,
  cmColorOfActivePath,
  cmColorOfActivePathClicked,
  cmColorOfActiveGo,
  cmColorOfClickedGo,
  cmColorOfGo,
  cmPathMenuOne,
  cmPathMenuTwo,
  cmPathABOUT,
  cmPathSDLCOne,
  cmPathSDLCTwo,
  cmPathBACKENDOne,
  cmPathBACKENDTwo,
  cmPathDATABASEOne,
  cmPathDATABASETwo,
  cmPathLANGUAGESOne,
  cmPathLANGUAGESTwo,
  cmPathTESTINGOne,
  cmPathTESTINGTwo,
  cmPathDEPLOYMENTOne,
  cmPathDEPLOYMENTTwo,
  cmPathFRONTENDOne,
  cmPathFRONTENDTwo,
  cmColorTextGo,
  cmOpacity,
  cmColorFirstCircle,
  cmColorSecondCircle

} from '../helpers/constants'
import { TemplateContext } from '../context/templateContext'


const CircleMenu = (props) => {
  const { circleObj, toggleCircleMenu, switchStatusCircleMenu, getCompressedCircleProperties, initToActiveStep, setParamsToActiveStep } = useContext(TemplateContext)
  const navigate = useNavigate();

  const refGo = useRef(null)
  const refSDLC = useRef(null)
  const refLineHorizontSDLC = useRef(null)
  const refLineVerticalSDLC = useRef(null)
  const refCircleSDLC = useRef(null)

  const refBackEnd = useRef(null)
  const refLineHorizontBackEnd = useRef(null)
  const refLineVerticalBackEnd = useRef(null)
  const refCircleBackEnd = useRef(null)

  const refDataBase = useRef(null)
  const refLineHorizontDataBase = useRef(null)
  const refLineVerticalDataBase = useRef(null)
  const refCircleDataBase = useRef(null)

  const refLanguages = useRef(null)
  const refLineHorizontLanguages = useRef(null)
  const refLineVerticalLanguages = useRef(null)
  const refCircleLanguages = useRef(null)

  const refDeployment = useRef(null)
  const refLineHorizontDeployment = useRef(null)
  const refLineVerticalDeployment = useRef(null)
  const refCircleDeployment = useRef(null)

  const refTesting = useRef(null)
  const refLineHorizontTesting = useRef(null)
  const refLineVerticalTesting = useRef(null)
  const refCircleTesting = useRef(null)

  const refFrontEnd = useRef(null)
  const refLineHorizontFrontEnd = useRef(null)
  const refLineVerticalFrontEnd = useRef(null)
  const refCircleFrontEnd = useRef(null)

  const circleMenuHook = useRef(null)
  const circleHook = useRef(null)

  const drawLinesOfMenu = () => {
    if (props.menu) {
      gsap.to(
        [refLineHorizontSDLC.current, refLineVerticalSDLC.current, refCircleSDLC.current,
        refLineHorizontBackEnd.current, refLineVerticalBackEnd.current, refCircleBackEnd.current,
        refLineHorizontDataBase.current, refLineVerticalDataBase.current, refCircleDataBase.current,
        refLineHorizontLanguages.current, refLineVerticalLanguages.current, refCircleLanguages.current,
        refLineHorizontTesting.current, refLineVerticalTesting.current, refCircleTesting.current,
        refLineHorizontDeployment.current, refLineVerticalDeployment.current, refCircleDeployment.current,
        refLineHorizontFrontEnd.current, refLineVerticalFrontEnd.current, refCircleFrontEnd.current
        ],
        { duration: props.animation, opacity: 0 }
      )
    } else {
      gsap.to(
        [refLineHorizontSDLC.current, refLineVerticalSDLC.current, refCircleSDLC.current,
        refLineHorizontBackEnd.current, refLineVerticalBackEnd.current, refCircleBackEnd.current,
        refLineHorizontDataBase.current, refLineVerticalDataBase.current, refCircleDataBase.current,
        refLineHorizontLanguages.current, refLineVerticalLanguages.current, refCircleLanguages.current,
        refLineHorizontTesting.current, refLineVerticalTesting.current, refCircleTesting.current,
        refLineHorizontDeployment.current, refLineVerticalDeployment.current, refCircleDeployment.current,
        refLineHorizontFrontEnd.current, refLineVerticalFrontEnd.current, refCircleFrontEnd.current
        ],
        { duration: props.animation, opacity: 1 }
      )
    }
  }
  const fillWithColor = (obj, color, nameOfproperty = 'fill') => {
    switch (nameOfproperty) {
      case 'stroke':
        gsap.to(
          [obj.current],
          { duration: 2, stroke: color, ease: 'back' }
        )
        break
      default:
        gsap.to(
          [obj.current],
          { duration: 0.9, fill: color, ease: 'back' }
        )
    }
  }

  const setColorOfAction = (fillColor, ...arrOfobj) => {
    arrOfobj.forEach((obj) => {
      if (obj.current.hasAttribute('stroke')) fillWithColor(obj, fillColor, 'stroke')
      else fillWithColor(obj, fillColor)
    })
  }

  const animateMenu = () => {
    if (circleMenuHook.current) {
      gsap.to(
        [circleMenuHook.current],
        0.5,
        { y: -1 },
        { y: 1, repeat: -1, yoyo: true }
      )
    }
  }

  const activateScene = (refItem, id) => {
    navigate("/");
    setColorOfAction(cmColorOfActivePath, refItem)
    toggleCircleMenu(id)
    initToActiveStep()
    if (!props.menu) switchStatusCircleMenu()
  }

  const returnToBaseScene = () => {
    navigate("/");
    setColorOfAction(cmColorOfGo, refGo)
    toggleCircleMenu('SDLC')
    switchStatusCircleMenu()
    initToActiveStep()
    setParamsToActiveStep('step', 0)
  }

  const getMenu = () => {
    return (
      <g id='circleMenu' ref={circleMenuHook}>
        <g key='defCircleMenu' ref={circleHook} transform={helper.moveToXY_ScaleXY(circleObj.circleX, circleObj.circleY, circleObj.scaleCircle, circleObj.scaleCircle)}>
          <path
            d={cmPathMenuOne}
            fill={cmColorFirstCircle}
            opacity={cmOpacity}
          />
          <path
            d={cmPathMenuTwo}
            fill={cmColorSecondCircle}
            opacity={cmOpacity}
          />
          <circle
            ref={refGo}
            onMouseEnter={() => setColorOfAction(cmColorOfActiveGo, refGo)}
            onMouseOut={() => setColorOfAction(cmColorOfGo, refGo)}
            onMouseDown={() => setColorOfAction(cmColorOfClickedGo, refGo)}
            onMouseUp={() => returnToBaseScene()}
            opacity='0.50' cx='250' cy='246' r='160' fill={cmColorOfGo}
          />
          <path
            onMouseEnter={() => setColorOfAction(cmColorOfActiveGo, refGo)}
            onMouseOut={() => setColorOfAction(cmColorOfGo, refGo)}
            onMouseDown={() => setColorOfAction(cmColorOfClickedGo, refGo)}
            onMouseUp={() => returnToBaseScene()}
            d={cmPathABOUT}
            fill={cmColorTextGo}
          />

          {setMenuItem('SDLC', cmColorSDLC, refSDLC, refLineVerticalSDLC, refCircleSDLC, refLineHorizontSDLC, cmPathSDLCOne, cmPathSDLCTwo, 5, 68, 97, 68, 260)}
          {setMenuItem('BACKEND', cmColorBackEnd, refBackEnd, refLineVerticalBackEnd, refCircleBackEnd, refLineHorizontBackEnd, cmPathBACKENDOne, cmPathBACKENDTwo, 10, 428, 153, 428, 328)}
          {setMenuItem('DATABASE', cmColorDataBase, refDataBase, refLineVerticalDataBase, refCircleDataBase, refLineHorizontDataBase, cmPathDATABASEOne, cmPathDATABASETwo, 10, 443, 288, 313, 443)}
          {setMenuItem('LANGUAGES', cmColorLanguages, refLanguages, refLineVerticalLanguages, refCircleLanguages, refLineHorizontLanguages, cmPathLANGUAGESOne, cmPathLANGUAGESTwo, 10, 378, 372, 298, 378)}
          {setMenuItem('TESTING', cmColorTesting, refTesting, refLineVerticalTesting, refCircleTesting, refLineHorizontTesting, cmPathTESTINGOne, cmPathTESTINGTwo, 5, 117, 372, 188, 118)}
          {setMenuItem('DEPLOYMENT', cmColorDeployment, refDeployment, refLineVerticalDeployment, refCircleDeployment, refLineHorizontDeployment, cmPathDEPLOYMENTOne, cmPathDEPLOYMENTTwo, 5, 57, 273, 108, 58)}
          {setMenuItem('FRONTEND', cmColorFrontEnd, refFrontEnd, refLineVerticalFrontEnd, refCircleFrontEnd, refLineHorizontFrontEnd, cmPathFRONTENDOne, cmPathFRONTENDTwo, 9, 88, 143, 142, 88)}

        </g>
      </g>
    )
  }

  useEffect(() => {
    getCompressedCircleProperties()
    if (circleMenuHook.current) {
      animateMenu()
      drawLinesOfMenu()
    }
    // eslint-disable-next-line
  }, [props])

  const setMenuItem = (id, color, refItem, refLineVertical, refCircle, refLineHorizont, path1, path2, r, x, y, s, e) => {
    return (
      <g
        id={id}
        key={id}
      >

        <g>
          <line
            ref={refLineVertical}
            x1={x} y1={y} x2={x} y2={`${circleObj.lines}`}
            strokeWidth='1'
            stroke={color}
          />

          <line
            ref={refLineHorizont}
            x1={s} y1={y} x2={e} y2={y}
            strokeWidth='1'
            stroke={color}
          />
          <circle
            ref={refCircle}
            cx={x} cy={y} r={r} fill={color}
          />
        </g>
        <path
          ref={refItem}
          onMouseOver={() => setColorOfAction(cmColorOfActivePath, refItem, refLineVertical, refCircle, refLineHorizont)}
          onMouseOut={() => setColorOfAction(color, refItem, refLineVertical, refCircle, refLineHorizont)}
          onMouseDown={() => setColorOfAction(cmColorOfActivePathClicked, refItem)}
          onMouseUp={() => activateScene(refItem, id)}
          d={path1}
          fill={color}
        />
        <path
          onMouseOver={() => setColorOfAction(cmColorOfActivePath, refItem, refLineVertical, refCircle, refLineHorizont)}
          onMouseOut={() => setColorOfAction(color, refItem, refLineVertical, refCircle, refLineHorizont)}
          onMouseDown={() => setColorOfAction(cmColorOfActivePathClicked, refItem)}
          onMouseUp={() => activateScene(refItem, id)}
          d={path2}
        />
      </g>

    )
  }

  return (
    circleObj.lines ? getMenu() : null
  )
}

export default memo(CircleMenu)
