import React, { createContext, useEffect, useState, memo } from 'react'
import helper from '../helpers/helper'
import axios from 'axios'
import Dexie from 'dexie'
// import { useLiveQuery } from 'dexie-react-hooks'
export const TemplateContext = createContext()

const db = new Dexie('_medatabase')

db.version(1).stores({

  appsettings: 'id, logo, quote, quotevertical',
  bodyitems: 'id, name, fullname, steps, titleshapes, uiobjects',
  shapes: 'id, name, gradient, idparent, matrix',
  titleshapes: 'id, name, background, color, path',
  uiobjects: 'id, name, shapes'

})

const TemplateContextProvider = (props) => {
  const [loading, setLoading] = useState()
  const [dbStore, setDbStore] = useState()
  const [quote, setQuote] = useState()
  const [quotevertical, setQuotevertical] = useState()
  const [logo, setLogo] = useState()
  const [heightHeader, setHeightHeader] = useState(null)
  const [heightFooter, setHeightFooter] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.width)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [svgHight, setSvgHight] = useState(windowHeight - heightHeader - heightFooter)
  const [activeElement, setActiveElement] = useState(null)
  const [activeStep, setActiveStep] = useState({ step: 0, additionFlag: false, skillTag: false })
  const [statusMenu, setStatusMenu] = useState(false)
  const [animation, setAnimation] = useState(1)
  const [layout, setLayout] = useState()
  const [circleObj, setCircleObj] = useState({ widthCircle: 0, circleX: 0, circleY: 0, scaleCircle: 1, lines: 0 })

  const detectLayout = () => {
    let layout = ''
    switch (window.innerWidth > window.innerHeight) {
      case (true):
        layout = 'Landscape'
        break
      default:
        layout = 'Portrait'
        break
    }

    return layout
  }

  const updateLayout = () => {
    setLayout(detectLayout())
  }

  const udateSides = () => {
    setWindowHeight(window.innerHeight)
    setWindowWidth(window.innerWidth)
    updateLayout()
  }

  const getBodyitems = (name) => {
    try {
      const arr = dbStore.bodyitems
      for (let i = 0; i <= arr.length; i++) {
        if (arr[i].name === name) return arr[i]
      }
    } catch {
      console.log('Error reading Data')
    }
  }

  const getUiobjects = async (id) => {
    if (typeof dbStore.uiobjects !== 'undefined') { return dbStore.uiobjects.find(item => item.id === id) }
  }

  const getShapes = async (id) => {
    if (typeof dbStore.shapes !== 'undefined') { return dbStore.shapes.find(item => item.id === id) }
  }

  const getTitles = async (id) => {
    if (typeof dbStore.titleshapes !== 'undefined') { return dbStore.titleshapes.find(item => item.id === id) }
  }

  const activeObject = async (name) => {
    const obj = { name: '', description: '', title: {}, uiobjects: [], steps: [] }

    let uiobject = {}
    let newuiobject = { name, shapes: [] }
    let shapes = []
    let newshape = {}
    let newshapeClone = {}
    const bodyitems = await getBodyitems(name)

    if (!helper.isUndefined(bodyitems)) {
      obj.steps = bodyitems.steps
      obj.name = bodyitems.name
      obj.description = bodyitems.fullname

      for (const n in bodyitems.titleshapes) {
        obj.title = await getTitles(bodyitems.titleshapes[n])
      }
      for (const k in bodyitems.uiobjects) {
        uiobject = await getUiobjects(bodyitems.uiobjects[k])

        if (!helper.isUndefined(uiobject)) {
          newuiobject.name = uiobject.name
          shapes = uiobject.shapes
          for (const j in shapes) {
            newshape = await getShapes(shapes[j])
            if (!helper.isUndefined(newshape)) {
              if (newshape.idparent) {
                newshapeClone = await getShapes(newshape.idparent)
                if (!helper.isUndefined(newshapeClone)) {
                  newshape.gradient = newshapeClone.gradient
                  newshape.properties = newshapeClone.properties
                  newuiobject.shapes.push(newshape)
                }
              } else newuiobject.shapes.push(newshape)
            }
          }
          obj.uiobjects.push(newuiobject)
          newuiobject = { name, shapes: [] }
        }
      }
    }
    return obj
  }

  const setParamsToActiveStep = (type, value) => {
    const tempActiveStep = activeStep
    tempActiveStep[type] = value
    setActiveStep(tempActiveStep)
  }

  const initToActiveStep = () => {
    setActiveStep({ step: 0, additionFlag: false, skillTag: false })
  }

  const toggleCircleMenu = async (clickedPathName) => {
    let newActiveObject = await activeObject(clickedPathName)
    setActiveElement(newActiveObject)
  }

  const switchStatusCircleMenu = () => {
    setStatusMenu(!statusMenu)
  }

  const putDataToDexie = (str, data) => {
    switch (str) {
      case 'appsettings':
        db.appsettings.bulkPut(data)
        break
      case 'titleshapes':
        db.titleshapes.bulkPut(data)
        break
      case 'shapes':
        db.shapes.bulkPut(data)
        break
      case 'bodyitems':
        db.bodyitems.bulkPut(data)
        break
      case 'uiobjects':
        db.uiobjects.bulkPut(data)
        break

      default:
        break
    }
  }
  const changeUpdateStatus = async () => {
    await axios.put(`${process.env.REACT_APP_API_URL}/update`)
      .catch((err) => {
        console.log(err)
      })
  }

  const getaDataFromTable = async (str, status) => {
    let dataFromTable = []
    await db.table(str).toArray()
      .then(async function (data) {
        if ((data.length === 0) || (status)) {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/${str}`)
            .catch((err) => {
              console.log(err)
            })
          if (res.data.length) {
            dataFromTable = res.data
            putDataToDexie(str, res.data)
          } else {
            console.log('DataBase empty')
          }
        } else {
          dataFromTable = data
        }
      })
      .catch((err) => {
        console.log(err + str)
      })
    setLoading(false)

    return dataFromTable
  }

  const getData = async () => {
    const db = { titleshapes: [], bodyitems: [], uiobjects: [], shapes: [] }
    let appsettings = []

    appsettings = await getaDataFromTable('appsettings', true)

    if (!helper.isUndefined(appsettings[0])) {
      db.titleshapes = await getaDataFromTable('titleshapes', appsettings[0].updstatus)
      db.bodyitems = await getaDataFromTable('bodyitems', appsettings[0].updstatus)
      db.shapes = await getaDataFromTable('shapes', appsettings[0].updstatus)
      db.uiobjects = await getaDataFromTable('uiobjects', appsettings[0].updstatus)

      setLogo(appsettings[0].logo)
      setQuote(appsettings[0].quote)
      setQuotevertical(appsettings[0].quotevertical)
      setDbStore(db)
      setLayout(detectLayout())
      if (appsettings[0].updstatus) changeUpdateStatus()
    }
  }

  useEffect(() => {
    window.addEventListener('resize', udateSides, false)
    getData()
    return () => {
      window.removeEventListener('resize', udateSides, false)
    }
    // eslint-disable-next-line
  }, [])

  const recalculateSvgHeight = () => {
    if ((heightHeader) && (heightFooter)) {
      const height = windowHeight - heightHeader - heightFooter
      setSvgHight(height)
    }
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    updateLayout()
    // eslint-disable-next-line
  }, [window.innerWidth, window.innerHeight])

  useEffect(() => {
    recalculateSvgHeight()
    // eslint-disable-next-line
  }, [heightHeader, heightFooter])

  const isStepsExists = () => {
    if (!helper.isUndefined(activeElement.steps)) { if (activeElement.steps.length) return true }
    return false
  }

  const getCompressedImageProperties = (imageW, imageH) => {
    let imageObj = null

    imageObj = { scale: 0, w: 0, h: 0, x: 0, y: 0, leftTop: 0 }
    const wMenu = helper.roundTo(svgHight / 4)
    const kMenu = helper.roundTo(wMenu / 700)
    imageObj.scale = helper.roundTo(svgHight / imageH)
    imageObj.w = helper.roundTo(imageW * imageObj.scale)
    imageObj.h = helper.roundTo(imageH * imageObj.scale)
    imageObj.leftTop = helper.roundTo(wMenu * kMenu)
    imageObj.x = helper.roundTo(windowWidth / 2 - imageObj.w / 2)

    return imageObj
  }

  const getCompressedCircleProperties = () => {

    let widthCircle = 500
    let scaleCircle = 1
    let circleX = 0
    let circleY = 0

    let menuProperties = []

    switch (statusMenu) {
      case true:

        if (layout.includes('Landscape')) {
          scaleCircle = (svgHight / 2) / 500
          circleY = 10
        }

        if (layout.includes('Portrait')) {
          scaleCircle = (windowWidth / 4) / 500
          circleY = 0
        }

        widthCircle = 500 * scaleCircle
        circleX = 0
        break

      case false:

        switch (layout) {
          case 'Landscape':
            scaleCircle = (windowWidth / 3) / 500
            widthCircle = 500 * scaleCircle
            circleX = windowWidth - widthCircle
            break
          case 'Portrait':
            scaleCircle = windowWidth / 600
            widthCircle = 500 * scaleCircle
            circleX = windowWidth / 2 - widthCircle / 2
            break
          default:
            break
        }
        break
      default:
        break
    }
    const lines = (window.innerHeight / scaleCircle)
    menuProperties = helper.roundToArr('circleX, ' + circleX,
      'circleY, ' + circleY,
      'scaleCircle, ' + scaleCircle,
      'widthCircle, ' + widthCircle,
      'lines, ' + lines)

    setCircleObj({
      circleX: menuProperties.circleX,
      circleY: menuProperties.circleY,
      scaleCircle: menuProperties.scaleCircle,
      widthCircle: menuProperties.widthCircle,
      lines: menuProperties.lines
    })

  }

  return (

    <TemplateContext.Provider
      value={{
        loading,
        layout,
        quote,
        quotevertical,
        logo,
        animation,
        svgHight,
        statusMenu,
        windowWidth,
        windowHeight,
        activeElement,
        activeStep,
        dbStore,
        circleObj,

        setLoading,
        setHeightHeader,
        setHeightFooter,
        setWindowHeight,
        setStatusMenu,
        setLayout,
        setActiveStep,
        setParamsToActiveStep,
        initToActiveStep: initToActiveStep,

        getCompressedImageProperties,
        getCompressedCircleProperties,

        toggleCircleMenu,
        switchStatusCircleMenu,
        setAnimation,
        isStepsExists

      }}
    >
      {props.children}
    </TemplateContext.Provider>

  )
}

export default memo(TemplateContextProvider)
