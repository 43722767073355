import React, { useEffect, useRef, useState, useContext, memo } from 'react'
import helper from '../helpers/helper'
import {
  asFontSizeSunTitle,
  asFontSizeSunSubtitle,
  asFontSizeSunDescription,
  asStepDescription,
  asYSubTitle,
  asStepDecriptionLine,
  asXOfTheCenter,
  asYOfTheCenter,
  asArrayIdsSteps
} from '../helpers/constants'
import UiObject from '../components/sceneOblects/uiObject'
import Title from './sceneOblects/title'
import Additions from '../components/sceneOblects/additions'
import { gsap } from 'gsap'
import { TemplateContext } from '../context/templateContext'

let goingToChangeOnlyData = true

const ActiveScene = props => {
  const { activeElement, activeStep, setParamsToActiveStep } = useContext(TemplateContext)

  const circleWindow = {
    color: '',
    stepNumber: 0,
    title: '',
    subtitle: '',
    descriptions: [],
    coordinates: { xTitle: 0, yTitle: 0, xSubTitle: 0, ySubTitle: 0, xDescriptionsCoordinates: [] },
    addition: [],
    openedAdditions: false
  }

  const [circleWindowData, setCircleWindowData] = useState(null)
  const [uiTags, setUiTags] = useState(null)
  const [skillDataTags, setSkillDataTags] = useState(null)
  const [hooks, setHooks] = useState()
  const [masterTl] = useState(gsap.timeline({ paused: true, ease: 'elastic', repeat: -1 }))
  const tempHooks = { soilHooks: [], treeHooks: [], cloudHooks: [], buildingHooks: [], carHook: null, helicopterHooks: [], skillHook: null, listHook: null }
  const descriptionsArray = [null, null, null]
  let sunHook = useRef(null)

  const putHooktoGroup = (name, ref) => {
    if (name.includes('tree')) tempHooks.treeHooks.push(ref)
    else if
    (name.includes('soil')) tempHooks.soilHooks.push(ref)
    else if
    (name.includes('cloud')) tempHooks.cloudHooks.push(ref)
    else if
    (name.includes('building')) tempHooks.buildingHooks.push(ref)
    else if
    (name.includes('car')) tempHooks.carHook = ref
    else if
    (name.includes('helicopter')) tempHooks.helicopterHook = ref
    else if
    (name.includes('skills')) tempHooks.skillHook = ref
    else if
    (name.includes('list')) tempHooks.listHook = ref
  }

  const getTitleshape = () => {
    const rows = []
    rows.push(
      <Title
        key={'key' + activeElement.title.name}
        title={activeElement.title}
        xTitle={props.xTitle}
        yTitle={props.yTitle}
        scaleTitle={props.scaleTitle}
        titleText={activeElement.description}
        width={props.width}
      />
    )

    return rows
  }

  const scaleTheObj = (obj, num) => {
    if (obj != null) {
      gsap.set(obj, { transformOrigin: 'center' })
      gsap.to(
        [obj],
        { scale: num }
      )
    }
  }
  const mouseMove = (group) => {
    scaleTheObj(group, 0.9)
    scaleTheObj(group, 1)
  }

  const toggleNextStep = () => {
    hideSkillScreen()
    let stage = 'stage'
    if (!helper.isUndefined(activeElement.steps)) {
      if (activeStep.step) circleWindow.stepNumber = activeStep.step + 1
      else circleWindow.stepNumber = circleWindow.stepNumber + 1

      if (circleWindow.stepNumber === activeElement.steps.length) {
        stage = 'end'
        circleWindow.stepNumber = 0
      } else stage += circleWindow.stepNumber

      setParamsToActiveStep('step', circleWindow.stepNumber)
      setParamsToActiveStep('additionFlag', false)

      putDataToCircleWindow()
      setCircleWindowData(circleWindow)
      turnOnAnimation('play', stage)
    }
  }

  const pause = () => {
    masterTl.pause()
  }

  const setDataToHooks = (tl, hook, x, y, s, a1, a2, b1, b2, opacity, stage) => {
    if (!helper.isUndefined(hook)) {
      tl.to(hook.current,
        {
          scale: 1 + s,
          x: a1 + a2 * x,
          y: b1 + b2 * y,
          opacity: opacity,
          duration: 0.5

        }, stage)
    }
  }
  const propertiesComponents = (tl, s, x, y, stage) => {
    tl.set(['#sun', '#soil6', '#soil5', '#soil4', '#soil3', '#soil2', '#soil1'], { transformOrigin: 'center' })
    setDataToHooks(tl, hooks.soilHooks[1], x, y, s, 0, 0, 0, 2, 1, stage)
    setDataToHooks(tl, hooks.soilHooks[2], x, y, s, 0, 0, 0, 3, 1, stage)
    setDataToHooks(tl, hooks.soilHooks[3], x, y, s, 0, 0, 0, 4, 1, stage)
    setDataToHooks(tl, hooks.soilHooks[4], x, y, s, 0, 0, 0, 5, 1, stage)
    setDataToHooks(tl, hooks.soilHooks[5], x, y, s, 0, 0, 0, 6, 1, stage)
    setDataToHooks(tl, hooks.soilHooks[6], x, y, s, 0, 0, 0, 7, 1, stage)
    setDataToHooks(tl, hooks.cloudHooks[0], x, y, s, 129, 1, 90, 0, 0.2, stage)
    setDataToHooks(tl, hooks.cloudHooks[1], x, y, s, 10, -1, 90, 0, 0.3, stage)

    setDataToHooks(tl, hooks.buildingHooks[3], x, y, s, 0, -6, 0, 7, 1, stage)
    setDataToHooks(tl, hooks.buildingHooks[4], x, y, s, 0, -6, 0, 6, 1, stage)
    setDataToHooks(tl, hooks.buildingHooks[6], x, y, s, 0, -6, 0, 7, 1, stage)
    setDataToHooks(tl, hooks.buildingHooks[1], x, y, s, 0, -7, 0, 3, 1, stage)

    setDataToHooks(tl, hooks.buildingHooks[0], x, y, s, 0, 4, 0, 4, 1, stage)
    setDataToHooks(tl, hooks.buildingHooks[2], x, y, s, 0, 6, 0, 6, 1, stage)
    setDataToHooks(tl, hooks.buildingHooks[5], x, y, s, 0, 6, 0, 6, 1, stage)

    setDataToHooks(tl, hooks.treeHooks[0], x, y, s, 0, 3, 0, 2, 1, stage)
    setDataToHooks(tl, hooks.treeHooks[1], x, y, s, 0, -3, 0, 2, 1, stage)
    setDataToHooks(tl, hooks.treeHooks[2], x, y, s, 0, 2, 0, 2, 1, stage)
    setDataToHooks(tl, hooks.treeHooks[3], x, y, s, 0, -2, 0, 2, 1, stage)
    setDataToHooks(tl, hooks.treeHooks[4], x, y, s, 0, -1.9, 0, 1.2, 1, stage)

    return tl
  }

  const itemStage = (tl, stage, i) => {
    propertiesComponents(tl, 0.1 * i, 10 * i, 10 * i, stage)
    tl.add(pause)
    return tl
  }

  const turnOnAnimation = (flag, stage = 'stage' + activeStep.step) => {
    if (flag === 'play') { masterTl.play(stage, false) } else { masterTl.seek(stage) }
  }

  const putDataToCircleWindow = () => {
    const data = activeElement.steps[activeStep.step]

    if (!helper.isUndefined(data)) {
      circleWindow.color = activeElement.title.background
      circleWindow.openedAdditions = activeStep.additionFlag
      if (!helper.isUndefined(data.title) &&
        !helper.isUndefined(data.subtitle) &&
        !helper.isUndefined(data.descriptions) &&
        !helper.isUndefined(data.addition)) {
        circleWindow.stepNumber = activeStep.step

        circleWindow.color = activeElement.title.background
        circleWindow.title = data.title.trim()
        circleWindow.coordinates.xTitle = helper.getXforTextFromStartPoint(asXOfTheCenter, circleWindow.title, asFontSizeSunTitle)
        circleWindow.coordinates.yTitle = asYOfTheCenter

        circleWindow.subtitle = data.subtitle.trim()
        circleWindow.coordinates.xSubTitle = helper.getXforTextFromStartPoint(asXOfTheCenter, circleWindow.subtitle, asFontSizeSunSubtitle)
        circleWindow.coordinates.ySubTitle = asYOfTheCenter + asYSubTitle

        if (data.descriptions.length !== 0) {
          circleWindow.descriptions = data.descriptions
          data.descriptions.forEach((element, i) => {
            if (!helper.isUndefined(element)) circleWindow.coordinates.xDescriptionsCoordinates[i] = helper.getXforTextFromStartPoint(asXOfTheCenter, element, asFontSizeSunDescription)
          })
        }
      }

      if (!helper.isUndefined(data.addition)) circleWindow.addition = data.addition
    }
  }

  const fillUiObject = (obj) => {
    const row = []
    let shapes = {}
    const ref = React.createRef()
    shapes = obj.shapes
    row.push(
      <UiObject
        ref={ref}
        key={'refKey' + obj.name}
        name={obj.name}
        shapes={shapes}
        layout={props.layout}
      />
    )
    putHooktoGroup(obj.name, ref)
    return row
  }

  const initSkillSetTag = () => {
    const yPos = props.layout.includes('Portrait') ? 100 : 60
    if ((hooks.helicopterHook.current) && (hooks.skillHook.current) && (hooks.listHook.current)) {
      if (activeStep.skillTag) {
        gsap.to([hooks.helicopterHook.current], { x: -900, duration: 0, opacity: 0, ease: 'none' })
        if (activeStep.step === 0) {
          gsap.to([hooks.skillHook.current, hooks.listHook.current], { x: 100, y: yPos, duration: 0, opacity: 1, ease: 'none' })
        } else {
          gsap.to([hooks.skillHook.current, hooks.listHook.current], { x: 100, y: yPos, duration: 0, opacity: 0, ease: 'none' })
        }
      } else {
        gsap.to([hooks.helicopterHook.current, hooks.skillHook.current, hooks.listHook.current],
          { x: 500, y: yPos, duration: 0, opacity: 0, ease: 'none' })
      }
    }
  }

  const hideHelicopter = () => {
    if (hooks.helicopterHook.current) { gsap.to(hooks.helicopterHook.current, { opacity: 0 }) }
  }

  const hideSkillScreen = () => {
    if ((hooks.helicopterHook.current) && (hooks.skillHook.current) && (hooks.listHook.current)) { gsap.to([hooks.helicopterHook.current, hooks.skillHook.current, hooks.listHook.current], { opacity: 0 }) }
  }

  const animateSkillSetTag = () => {
    const yPos = props.layout.includes('Portrait') ? 160 : 60
    if (activeStep) {
      if ((activeStep.step === 0) && (!activeStep.skillTag)) {
        if ((hooks.helicopterHook.current) && (hooks.skillHook.current) && (hooks.listHook.current)) {
          gsap.to(
            [hooks.helicopterHook.current, hooks.skillHook.current, hooks.listHook.current],
            { x: 100, y: yPos, duration: 1, opacity: 1, ease: 'sine' }
          )
          gsap.to(
            hooks.helicopterHook.current,
            { x: -900, y: 10, duration: 2, opacity: 1, ease: 'sine', delay: 1, onComplete: hideHelicopter }
          )
          setParamsToActiveStep('skillTag', true)
        }
      }
    }
  }

  const setTextElement = (id, container, x, y) => {
    return (
      <text
        id={id}
        key={id}
        transform={`translate(${x} ${y})`}
        fontSize={20}
        fontWeight={400}
      >
        {container}
      </text>
    )
  }

  const setLinesOfText = (arr) => {
    const rows = []
    for (let i = 0; i < arr.length; i++) {
      rows.push(setTextElement('skillLine' + i, '->' + arr[i], 330, 450 + i * 20))
    }
    return rows
  }

  const getUiTagsFromDatabase = () => {
    const uiTags = []
    for (let i = 0; i < activeElement.uiobjects.length; i++) {
      if ((!activeElement.uiobjects[i].name.includes('helicopter')) && (!activeElement.uiobjects[i].name.includes('skills'))) {
        uiTags.push(fillUiObject(activeElement.uiobjects[i]))
      }
    }
    setUiTags(uiTags)
  }

  const buttonMorePressed = () => {
    if (activeStep.additionTag) return true
    else return false
  }

  const getSkillTagsFromDatabase = () => {
    const skillTags = []

    for (let i = 0; i < activeElement.uiobjects.length; i++) {
      if ((activeElement.uiobjects[i].name.includes('helicopter')) || (activeElement.uiobjects[i].name.includes('skills'))) { skillTags.push(fillUiObject(activeElement.uiobjects[i])) }
    }
    if (!buttonMorePressed()) {
      if (!helper.isUndefined(activeElement.steps)) {
        if (!helper.isUndefined(activeElement.steps[0])) {
          if (activeElement.steps[0].graphics) {
            const ref = React.createRef()
            skillTags.push(
              <g id='skillsList' ref={ref} key='My skillset' opacity='0'>
                {setTextElement('skillTitle', 'My skillset:', 365, 415)}
                {setLinesOfText(activeElement.steps[0].graphics)}
              </g>)
            putHooktoGroup('list', ref)
          }
        }
      }
    }
    setSkillDataTags(skillTags)
  }

  const putData = () => {
    putDataToCircleWindow()
  }

  useEffect(() => {
    goingToChangeOnlyData = false
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!helper.isUndefined(activeElement.steps)) {
      if (activeElement.steps.length > 0) {
        getUiTagsFromDatabase()
        getSkillTagsFromDatabase()
        setHooks(tempHooks)
        putData()
        setCircleWindowData(circleWindow)
      } else setCircleWindowData(null)
    }

    // eslint-disable-next-line
  }, [activeElement])

  useEffect(() => {
    const setUpParametersForAnimation = () => {
      if (hooks) {
        if (!helper.isUndefined(activeElement.steps)) {
          masterTl.clear()
          for (let i = 0; i < activeElement.steps.length; i++) itemStage(masterTl, 'stage' + i, i)
          itemStage(masterTl, 'end', 0)

          goingToChangeOnlyData ? turnOnAnimation('play') : turnOnAnimation('seek')
          initSkillSetTag()
          putColorHelicapterScreen()
          setColorCar()
          animateSkillSetTag()
          goingToChangeOnlyData = true
        }
      }
    }

    setUpParametersForAnimation()

    // eslint-disable-next-line
  }, [hooks])

  const mouseDown = (e) => {
    if (!helper.isUndefined(e.target.attributes.id)) {
      if (asArrayIdsSteps.includes(e.target.attributes.id.value)) {
        mouseMove(sunHook)
        toggleNextStep()
      }
    }
  }

  const setColorCar = () => {
    const newColor = helper.getDarkerColor(circleWindowData.color, -60)

    gsap.to(
      ['#carpart2'],
      { fill: circleWindowData.color, duration: 0 }
    )

    gsap.to(
      ['#carpart1'],
      { fill: newColor, duration: 0 }
    )
  }

  const putColorHelicapterScreen = () => {
    const newColor = helper.getDarkerColor(circleWindowData.color, -20)
    gsap.to(
      ['#helicopterScreen1'],
      { fill: newColor, opacity: 1 }
    )
  }

  const getPopUp = () => {
    const rows = []

    if (!helper.isUndefined(circleWindowData.addition)) {
      if (circleWindowData.addition.length !== 0) {
        rows.push(<Additions
          key={'popUp' + circleWindowData.stepNumber}
          last={circleWindowData.stepNumber === (activeElement.steps.length - 1)}
          first={circleWindowData.stepNumber === 1}
          addition={circleWindowData.addition}
          carHook={hooks.carHook}
          color={circleWindowData.color}
          active={activeStep}
          layout={props.layout}
                  />)
      }
    }

    return rows
  }

  const getDescriptions = () => {
    const rows = []
    let element = null
    const ref = null
    for (let i = 0; i <= 2; ++i) {
      element = circleWindowData.descriptions[i]
      descriptionsArray[i] = React.createRef()
      const step = asStepDescription + asStepDecriptionLine * i
      rows.push(
        <text
          id={'descriptionLine' + i}
          key={'descriptionLine' + i}
          ref={el => { descriptionsArray[i] = el }}
          onMouseDown={(e) => mouseDown(e)}
          fontSize={asFontSizeSunDescription}
          transform={helper.moveToXY(circleWindowData.coordinates.xDescriptionsCoordinates[i], circleWindowData.coordinates.ySubTitle + step * asFontSizeSunDescription)}
          fontWeight={400}
        >
          {element}
        </text>
      )
      descriptionsArray.push(ref)
    }
    return rows
  }

  const getCircleWindows = () => {
    return (
      <g
        id='sun'
        key='sun'
        ref={el => { sunHook = el }}

      >
        <circle
          id='sunFirstCircle'
          key='sunFirstCircle'
          onMouseDown={(e) => mouseDown(e)}
          cx={510}
          cy={310}
          r={250}
          fill='#FFF'
          opacity={0.7}
        />
        <circle
          id='sunSecondCircle'
          key='sunSecondCircle'
          // onMouseEnter={(e) => mouseEnter(e)}
          // onMouseOut={(e) => mouseOut(e)}
          onMouseDown={(e) => mouseDown(e)}
          // onMouseUp={(e) => mouseUp(e)}
          cx={510}
          cy={310}
          r={210}
          fill={circleWindowData.color}
        />
        <circle
          id='sunThirdCircle'
          key='sunThirdCircle'
          onMouseDown={(e) => mouseDown(e)}
          cx={510}
          cy={310}
          r={200}
          fill='#FFF'
        />
        <text
          id='sunTitle'
          key='sunTitle'
          onMouseDown={(e) => mouseDown(e)}
          transform={helper.moveToXY(circleWindowData.coordinates.xTitle, circleWindowData.coordinates.yTitle)}
          fontSize={asFontSizeSunTitle}
          fontWeight={400}
        >
          {circleWindowData.title}
        </text>
        <text
          id='sunSubTitle'
          key='sunSubTitle'
          onMouseDown={(e) => mouseDown(e)}
          transform={helper.moveToXY(circleWindowData.coordinates.xSubTitle, circleWindowData.coordinates.ySubTitle)}
          fontSize={asFontSizeSunSubtitle}
          fontWeight={800}
        >
          {circleWindowData.subtitle}
        </text>
        {getDescriptions()}
        {getPopUp()}
      </g>
    )
  }

  if (circleWindowData && skillDataTags && hooks) {
    return (
      <g id={activeElement.name} key={activeElement.name} transform={helper.moveToXY_ScaleXY(props.x, props.y, props.scale)}>
        {uiTags}
        {getCircleWindows()}
        {getTitleshape()}
        {skillDataTags}
      </g>
    )
  } else return null
}

export default memo(ActiveScene)
