/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { ReactComponent as GitHub } from "../images/github.svg"
import { ReactComponent as Node } from "../images/nodedotjs.svg"
import { ReactComponent as Javascript } from "../images/javascript.svg"
import { ReactComponent as Html } from "../images/html5.svg"
import { ReactComponent as Css } from "../images/css3.svg"
import { ReactComponent as ReactC } from "../images/react.svg"
import { ReactComponent as Redux } from "../images/redux.svg"
import { ReactComponent as Postgresql } from "../images/postgresql.svg"
import Intro from "../images/intro.png"
import Game from "../images/game.png"
const Portfolio = (props) => {

  return (
    <div className='content'>
      <figure>
        <figcaption>
          <a href="https://github.com/dithiane/swapi-squadron-race">
            Squadron StarWar race Game
            <GitHub className="icon" />
          </a>
        </figcaption>
        <a href="https://swapi-squadron-race1.onrender.com">
          <img className="intro" src={Intro} alt="Intro" />
        </a>
        <a href="https://swapi-squadron-race1.onrender.com/">
          <img className="intro" src={Game} alt="Game" />
        </a>
      </figure>
      <h1>Squadrons, players assume the role of a starfighter pilot. Players engage in a race in the space, can upgrade their speed, remove opponents, and be winner.</h1>
      <section className="info">
        <div className="section-name">Technologies Used</div>
        <div className="icons-container">
          <Node className="icon" />
          <Javascript className="icon" />
          <Html className="icon" />
          <Css className="icon" />
          <ReactC className="icon" />
          <Redux className="icon" />
          <Postgresql className="icon" />
        </div>
      </section>
    </div>
  )
}

export default Portfolio
