
const pixelWidth = require('string-pixel-width')
const
  {
    fontSize,
    bdHLandscape,
    bdHPortrait,
    tlXBig,
    tlYBig,
    tlScaleBig,
    tlXSmall,
    tlYSmall,
    tlScaleSmall,
    adYSkillsLandscape,
    adYSkillsPortrait,
    adWorkingPositionLanscape,
    adWorkingPositionPortrait,
    adTranslateBackgroundLandscape,
    adTranslateBackgroundPortrait,
    uoXLandscape,
    uoXPortrait,
    uoYLandscape,
    uoYPortrait

  } = require('./constants')

module.exports = {

  //   colorSDLC: '#45d545',
  //   colorBackEnd: '#4a86e8',
  //   colorDataBase: '#ff00ff',
  //   colorLanguages: '#fcfcfc',
  //   colorDeployment: '#ff9900',
  //   colorTesting: '#00ffff',
  //   colorFrontEnd: '#ffff00',
  //   colorOfActivePath: '#da1c30',
  //   colorOfActivePathClicked: '#980000',
  //   colorOfActiveGo: '#093E95',
  //   colorOfClickedArrow: '#171760',
  //   colorOfActiveArrow: '#093E95',
  //   colorOfArrow: '#FFFFFF',
  //   colorOfClickedGo: '#000000',
  //   colorOfGo: '#4A86E8',
  //   fontSize: 40,
  //   fontColor: '#fcfcfc',
  //   fontSizeTitle: 54,
  //   fontSizeSubtitle: 36,
  //   fontSizeText: 24,
  //   hightHeader: 120,
  //   highFooter: 35,
  //   hightFooter: 40,
  //   startPosition: 800,
  //   endPosition: 1500,
  //   workingPositionLanscape: 80,
  //   workingPositionPortrait: 390,

  //   deltaTextTitle: 6,
  //   xOfTheCenter: 510,
  //   yOfTheCenter: 250,
  //   heightFont: 20,
  //   xOfTheTitleText: 258,
  //   yOfTheTitleText: 55,

  //   xTitleSmall: 389,
  //   yTitleSmall: 0,
  //   scaleSmallTitle: 0.5,
  //   xTitleBig: 310,
  //   yTitleBig: 0,
  //   scaleBigTitle: 0.8,
  //   arrayIdsPopUps: ['addInfo', 'screenPopUp', 'screenBackround', 'buttonPopUp', 'buttonBackground', 'buttonText'],
  //   arrayIdsSteps: ['sun', 'sunFirstCircle', 'sunSecondCircle', 'sunThirdCircle', 'title', 'headerText', 'descriptionLine1', 'descriptionLine2', 'descriptionLine3'],

  getDarkerColor (color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
  },

  initCoordinates (coordinate) {
    if (coordinate === undefined) {
      return '0'
    }
    return coordinate
  },
  moveToXY (x, y) {
    return 'translate(' + this.initCoordinates(x) + ' ' +
            this.initCoordinates(y) + ')'
  },

  getCenterCoordinates (w, h) {
    const targetXY = {}
    targetXY.x = this.roundTo(window.innerWidth / 2 - w / 2)
    targetXY.y = this.roundTo(window.innerHeight / 2 - h / 2)
    return targetXY
  },

  isValid (child, root = true) {
    if (!this.isUndefined(root)) {
      if (!this.isUndefined(child)) {
        if (!this.isUndefined(child.current)) { return true }
      }
    }
    return false
  },

  isValueableChange (prev, next) {
    if (Math.abs(prev - next) >= 50) return true
    else return false
  },

  rotateToDeg (deg) {
    return 'rotate(' + this.initCoordinates(deg) + ')'
  },
  moveToXY_ScaleXtoXs_ScaleYtoYs_SkewXToXDeg_SkewYToYDeg (x, y, xs, ys, xdeg, ydeg) {
    return 'matrix(' + this.initCoordinates(xs) + ' ' +
            this.initCoordinates(ydeg) + ' ' +
            this.initCoordinates(xdeg) + ' ' +
            this.initCoordinates(ys) + ' ' +
            this.initCoordinates(x) + ' ' +
            this.initCoordinates(y) + ')'
  },

  moveToXY_ScaleXY (xTranslate, yTranslate, xScale, yScale = xScale) {
    return 'matrix(' + this.initCoordinates(xScale) + ' ,0 ,0 ,' +
            this.initCoordinates(yScale) + ', ' +
            this.initCoordinates(xTranslate) + ', ' +
            this.initCoordinates(yTranslate) + ')'
  },

  concatinatedMatrixToFitInSVGAreas (width, withElement, height, heightElement) {
    return 'matrix(' + Number((width / withElement).toFixed(0)) + ', 0, 0, ' + Number((height / heightElement).toFixed(0)) + ', 0,0)'
  },

  concatinatedViewBox (w, h) {
    w = this.initCoordinates(w)
    h = this.initCoordinates(h)

    return '0 0 ' + w + ' ' + h
  },

  roundToArr (...arrOfElements) {
    let keys = []
    const obj = {}
    arrOfElements.forEach(element => {
      keys = element.split(',')
      obj[keys[0]] = parseFloat(keys[1]).toFixed(2)
    })

    return obj
  },
  roundTo (element) {
    if (!this.isUndefined(element)) { return parseFloat(element.toFixed(2)) }
    return ''
  },
  getWidthInPix (text, currentFontSize = fontSize) {
    return this.roundTo(pixelWidth(text.trim(), { font: 'open sans', size: currentFontSize }))
  },
  getXforTextFromStartPoint (xStart, text, currentFontSize = fontSize) {
    if ((typeof text !== 'undefined') && (text !== '')) {
      return this.roundTo(xStart - this.getWidthInPix(text, currentFontSize) / 2)
    } else return 0
  },

  getPropertyForLayout (layout) {
    const titleProps = {}

    if (layout) {
      switch (layout) {
        case 'Landscape':

          titleProps.x = tlXBig
          titleProps.y = tlYBig
          titleProps.scale = tlScaleBig
          break
        case 'Portrait':

          titleProps.x = tlXSmall
          titleProps.y = tlYSmall
          titleProps.scale = tlScaleSmall
          break
        default:
          break
      }
    }
    return titleProps
  },

  isUndefined (obj) {
    if (typeof obj === 'undefined') return true
    return false
  },

  isEmptyArr (arr = []) {
    if (arr.length === 0) return true
  },

  getConstantBy (moduleName, property) {
    switch (moduleName) {
      case 'Additions_skills':
        switch (property) {
          case 'Landscape': return adYSkillsLandscape
          case 'Portrait': return adYSkillsPortrait
          default: break
        }
        break
      case 'Additions_car':
        switch (property) {
          case 'Landscape': return adWorkingPositionLanscape
          case 'Portrait': return adWorkingPositionPortrait
          default: break
        }
        break
      case 'Additions_background':
        switch (property) {
          case 'Landscape': return adTranslateBackgroundLandscape
          case 'Portrait': return adTranslateBackgroundPortrait
          default: break
        }
        break
      case 'Uiobject_x':
        switch (property) {
          case 'Landscape': return uoXLandscape
          case 'Portrait': return uoXPortrait
          default: break
        }
        break
      case 'Uiobject_y':
        switch (property) {
          case 'Landscape': return uoYLandscape
          case 'Portrait': return uoYPortrait
          default: break
        }
        break
      case 'Body_hight':
        switch (property) {
          case 'Landscape': return bdHLandscape
          case 'Portrait': return bdHPortrait
          default: break
        }
        break
      default: break
    }
  }
}
