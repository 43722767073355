
import React, { useEffect } from 'react'
import { gsap } from 'gsap'

const Sky = () => {
  useEffect(() => {
    const introTl = gsap.timeline({ paused: true })
    introTl.fromTo('.skiesBlue', {
      autoAlpha: 0.4,
      scale: 2.0
    }, {
      autoAlpha: 1.0,
      scale: 1.0,
      duration: 1
    }, 0)
    introTl.play()
  })

  return (
    <div className='skiesBlue' />
  )
}
export default Sky
