import React from 'react'
import './css/App.css'
import Template from './components/template'
import { HashRouter } from 'react-router-dom'

export function App() {
  return (
    <HashRouter basename="/">
      <Template />
    </HashRouter>
  )
}
