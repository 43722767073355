import React, { memo } from 'react'
import Shape from './shape'
import helper from '../../helpers/helper'
import {
  uoModuleX,
  uoModuleY
} from '../../helpers/constants'

const UiObject = React.forwardRef((props, ref) => {
  const getShapes = () => {
    const startY = helper.getConstantBy(uoModuleY, props.layout)
    const startX = helper.getConstantBy(uoModuleX, props.layout)

    const rows = []
    for (const i in props.shapes) {
      if (!helper.isUndefined(props.shapes[i])) {
        rows.push(
          <Shape key={props.shapes[i].name} internals={props.shapes[i]} />
        )
      }
    }

    if (props.name.includes('car')) {
      return (
        <g
          ref={ref}
          key={props.name}
          id={props.name}
          opacity='0'
          transform={getTransform(startX, startY)}
        >
          {rows}
        </g>
      )
    } else if
    ((props.name.includes('helicopter')) || (props.name.includes('skills'))) {
      return (
        <g
          ref={ref}
          key={props.name}
          id={props.name}
          opacity='0'
        >
          {rows}
        </g>
      )
    } else if
    (props.name.includes('cloud')) {
      return (
        <g
          ref={ref}
          key={props.name}
          id={props.name}
          opacity='0.3'
          transform={props.name.includes('1') ? getTransform(130, 90) : getTransform(100, 130)}
        >
          {rows}
        </g>
      )
    } else {
      return (
        <g
          ref={ref}
          key={props.name}
          id={props.name}
        >
          {rows}
        </g>
      )
    }
  }
  const getTransform = (x, y) => {
    return helper.moveToXY_ScaleXY(x, y, 1, 1)
  }

  return (
    <>
      {getShapes()}
    </>
  )
})
export default memo(UiObject)
